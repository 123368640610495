import React from "react";
import { jumpList } from "../const";
import iconEmail from "../images/bottom/icon_email.png";

function NavFooter() {
  return (
    <div className="mobile-nav-footer">
      <div className="mobile-nav-tip">Join our community</div>
      <div className="mobile-nav-media">
        {jumpList.map(({ target, href, src, alt }) => (
          <a key={alt} target={target} href={href}>
            <img src={src} alt={alt} />
          </a>
        ))}
      </div>
      <div className="mobile-nav-email">
        Copyright © LiquidPlay Ltd.
        {/* <img src={iconEmail} alt="" />
        <a target={'_blank'} href="mailto:business@tinyworlds.io">business@tinyworlds.io</a> */}
      </div>
    </div>
  );
}

export default NavFooter;
