import brand from "../images/section-home/brand.png"
import cirlce from "../images/section-home/home-circle.png"
import mobileShadow from "../images/section-home/mobile-spine-shadow.png"
import { useRef } from "react";
import { useThrottle, useThrottleCallback } from "@react-hook/throttle";
import CharacterSpine from "./Spine";
import useIsMobile from "../utils/useIsMobile";
import launch from "../images/link/button/launch-normal.png";

export default function SectionHome({setHomeCanvasLoaded}) {
  const isMobile = useIsMobile()
  const bgRef = useRef()
  
  const handleMousemove = (e) => {
    const x = e.clientX / 100, y = e.clientY / 100;
    bgRef.current.style.backgroundPositionX = x + 'px'
    bgRef.current.style.backgroundPositionY = y + 'px'
  }

  const throttledMousemove = useThrottleCallback(handleMousemove, 60, true)


  return (
    <section className="home-section" onMouseMove={throttledMousemove}>

      <div ref={bgRef} className="home-bg" />

      <div className="brand">
        <img className="brand-logo" width={880} height={578} src={brand} alt="" />

        <a href="https://app.tinyworlds.io" target="_blank" rel="noreferrer">
          <button className="launch-button">LAUNCH APP</button>
        </a>
      </div>

      {
        isMobile && (
          <>
            <img className="mobile-brand-logo" src={brand} alt="" />

            <a href="https://app.tinyworlds.io" className="mobile-launch-anchor" target="_blank" rel="noreferrer">
              <button className="launch-button mobile-launch-button">LAUNCH APP</button>
            </a>
          </>
        )
      }

      <img loading="eager" className="home-circle" src={cirlce} alt="" />
      {
        isMobile && (
          <img className="mobile-home-shadow" src={mobileShadow} alt="" />
        )
      }
      
      <CharacterSpine setHomeCanvasLoaded={setHomeCanvasLoaded} />
    </section>
  );
}
