import clsx from "clsx";
import star from "../images/star_select.svg";
import star_unselect from "../images/star_unselect.svg";
import logo from "../images/logo.png";

import { useEffect, useRef, useState } from "react";
import { headerList } from "../utils/constants";
import NavFooter from "./NavFooter";

export default function MobileHeader({ active, slideTo }) {
  const handleNavClick = (e) => {
    setExpand(!expand);

    const activeNum = Number(e.target.dataset.active);
    slideTo(activeNum);
  };

  const [firstLoad, setFirstLoad] = useState(true);
  const [expand, setExpand] = useState(false);

  const [hideNav, sethideNav] = useState(true);

  useEffect(() => {
    let hideNavs;

    if (expand) {
      sethideNav(false);
    } else {
      hideNavs = setTimeout(() => {
        sethideNav(true);
      }, 500);
    }

    return () => clearTimeout(hideNavs);
  }, [expand]);

  const handleClick = () => {
    setExpand(!expand);
    setFirstLoad(false);
  };

  return (
    <>
      <div
        className={`mobile-header ${
          firstLoad ? "" : expand ? "show-header-bg" : "hide-header-bg"
        }`}
      >
        <img
          className={
            "mobile-header-logo " +
            `${
              active !== 0 || expand
                ? "show-mobile-header-logo"
                : "hidden-mobile-header-logo"
            }`
          }
          src={logo}
          alt=""
        />
        <div
          className={`mobile-menu ${
            firstLoad ? "" : expand ? "menu-expand" : "menu-close"
          }`}
          onClick={handleClick}
        >
          <span />
          <span />
          <span />
        </div>
      </div>

      <div
        className={`mobile-nav ${expand ? "show-nav" : "hide-nav"}`}
        style={hideNav ? { display: "none" } : {}}
      >
        <ul className="mobile-nav-items">
          {headerList.map(({ text, href }, index) => {
            return (
              <li
                key={text}
                className={clsx(
                  expand ? "bounceIn " : "",
                  active === index ? "active" : ""
                )}
                style={{ animationDelay: `${index * 0.1}s` }}
              >
                {active === index ? (
                  <img src={star} alt="" />
                ) : (
                  <img src={star_unselect} alt="" />
                )}

                {href ? (
                  <a
                    data-active={index}
                    href={href}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {text}
                  </a>
                ) : (
                  <span data-active={index} onClick={handleNavClick}>
                    {text}
                  </span>
                )}
              </li>
            );
          })}
        </ul>
        <NavFooter></NavFooter>
      </div>
    </>
  );
}
