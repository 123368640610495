import { useMemo, useRef, useState, useEffect } from "react";
import "./App.css";
import 'animate.css';
import Header from "./components/Header.jsx";
import Footer from "./components/Footer.jsx";

import "./components/section.css";
import "./components/mobile.css";
import SectionPartners from "./components/SectionPartners";
import SectionVideo from "./components/SectionVideo";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Mousewheel, Pagination, Lazy } from "swiper";
import SectionHome from "./components/SectionHome";
import SectionCarousel from "./components/SectionCarousel";
import SectionGames from "./components/SectionGames";
import SectionToken from "./components/SectionToken";
import SectionRoadMap from "./components/SectionRoadMap";
import SectionRoadMapMobile from "./components/SectionRoadMapMobile";
import MobileHeader from "./components/MobileHeader";
import useIsMobile from "./utils/useIsMobile";
import SectionPartnersMobile from "./components/SectionPartnersMobile";

import { ReactComponent as LoadingAvatar } from"./images/loading-tiny.svg"
import loadingStar from "./images/star_unselect.svg"
import AffixNav from "./components/AffixNav";

function App() {
  const isMobile = useIsMobile();
  const [activeIndex, setActiveIndex] = useState(0);
  const [headerActiveIndex, setHeaderActiveIndex] = useState(0);

  const [swiper, setSwiper] = useState(null);
  const [toFooter, setToFooter] = useState(false);

  const [allImagesReady, setAllImagesReady] = useState(false)

  const [homeCanvasLoaded, setHomeCanvasLoaded] = useState(false)

  useEffect(() => {
    if (activeIndex === 7) {
      setToFooter(true);
    } else {
      setToFooter(false);
    }
  }, [activeIndex]);

  const handleSlideTransitionEnd = (swiper) => {
    // 进场动画在轮播动画结束后播放
    // console.log(swiper.activeIndex);
    setActiveIndex(swiper.activeIndex);
    // swiper.slideTo(swiper.activeIndex);
  };

  const handleSlideTransitionStart = (swiper) => {  
    setHeaderActiveIndex(swiper.activeIndex);
  }

  const [showLoading, setShowLoading] = useState(true)
  useEffect(() => {
    let id = null;
    if (allImagesReady && homeCanvasLoaded) {
      id = setTimeout(() => {
        setShowLoading(false)
      }, 1000)
    }

    return () => {
      if (id) {
        clearTimeout(id)
      }
    }
  }, [allImagesReady, homeCanvasLoaded, setShowLoading])

  const Loading = () => (
    <div className={`tiny-loading ${allImagesReady && homeCanvasLoaded && 'animate__fadeOut'}`}>
      <div className="loading-box">
        <LoadingAvatar className="loading-avatar" loading='eager' width="150" height="212" />
        <div className="loading-rotate-box">
          <img className="loading-star" loading="eager" src={loadingStar} alt="" />
        </div>
      </div>
    </div>
  )

  return (
    <div
      className="App swiper-container"
    >
      {
        showLoading && <Loading />
      }
      {isMobile ? (
        <MobileHeader
          active={headerActiveIndex}
          slideTo={(index) => swiper.slideTo(index)}
        />
      ) : (
        <Header
          active={headerActiveIndex}
          toFooter={toFooter}
          slideTo={(index) => swiper.slideTo(index)}
        />
      )}

      {
        !isMobile && <AffixNav changeBackground={headerActiveIndex !== 0} />
      }

      <Swiper
        direction={"vertical"}
        slidesPerView={"auto"}
        speed={1000}
        mousewheel={true}
        allowSlideNext={activeIndex < 7}
        allowSlidePrev={activeIndex > 0}
        allowTouchMove={isMobile ? true : false}
        pagination={false}
        modules={[Pagination, Lazy, Mousewheel]}
        className="home-swiper"
        noSwipingClass="mobile-noswiping-content"
        onSwiper={(swiper) => {
          setSwiper(swiper);
        }}
        onImagesReady={() => setAllImagesReady(true)}
        onTransitionStart={(x) => handleSlideTransitionStart(x)}
        onTransitionEnd={(x) => handleSlideTransitionEnd(x)}
      >
        <SwiperSlide>
          <SectionHome isActive={activeIndex === 0} setHomeCanvasLoaded={setHomeCanvasLoaded} />
        </SwiperSlide>

        <SwiperSlide>
          <SectionVideo isActive={activeIndex === 1} />
        </SwiperSlide>

        <SwiperSlide>
          <SectionCarousel isActive={activeIndex === 2} />
        </SwiperSlide>

        <SwiperSlide>
          <SectionGames isActive={activeIndex === 3} />
        </SwiperSlide>

        <SwiperSlide>
          <SectionToken isActive={activeIndex === 4} />
        </SwiperSlide>

        <SwiperSlide className="roadmap-slide">
          {isMobile ? (
            <SectionRoadMapMobile
              toNext={() => swiper.slideNext()}
              toPrev={() => swiper.slidePrev()}
            />
          ) : (
            <SectionRoadMap isActive={activeIndex === 5} />
          )}
        </SwiperSlide>

        <SwiperSlide>
          {
            isMobile ? (
              <SectionPartnersMobile
                toNext={() => swiper.slideNext()}
                toPrev={() => swiper.slidePrev()}
              />
            ) : (
              <SectionPartners isActive={activeIndex === 6 || activeIndex === 7} />
            )
          }
        </SwiperSlide>
        <SwiperSlide>
          <Footer
            slideTo={(index) => {
              swiper.slideTo(index);
            }}
            isActive={activeIndex === 7}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default App;
