import PIXI from "../pixiSpine";
import "pixi-spine";
import { useEffect, useRef } from "react";
import clsx from "clsx";
import { isCompositeComponent } from "react-dom/test-utils";

const isMobile = window.innerWidth < 600;

const isHD = window.devicePixelRatio >= 2 && isMobile;
// const isHD = true;

const canvasWidth = isHD ? window.innerWidth * 2 : window.innerWidth;
const canvasHeight = isHD ? window.innerHeight * 2 : window.innerHeight;

const brutus = new PIXI.Application({
  width: canvasWidth,
  height: canvasHeight,
  transparent: true,
});

// load spine data
const loadCanvas = brutus.loader
  .add("spinebrutus", "/pixi-spine/605/605.json")
  .add("spinejoan", "/pixi-spine/615/615.json")
  .add("spineromulus", "/pixi-spine/602/602.json")
  .load((loader, res) => {
    const spineBrutus = new PIXI.spine.Spine(res.spinebrutus.spineData);
    const spineRomulus = new PIXI.spine.Spine(res.spineromulus.spineData);
    const spineJoan = new PIXI.spine.Spine(res.spinejoan.spineData);

    const romulusShadow = PIXI.Sprite.from("/pixi-spine/romulus-shadow.png");
    const joanShadow = PIXI.Sprite.from("/pixi-spine/joan-shadow.png");

    const mobileShadow = PIXI.Sprite.from(
      "/pixi-spine/mobile-spine-shadow.png"
    );

    spineBrutus.zIndex = 5;
    spineJoan.zIndex = 3;
    spineRomulus.zIndex = 1;

    joanShadow.zIndex = 4;
    romulusShadow.zIndex = 2;
    mobileShadow.zIndex = 6;

    spineJoan.state.setAnimation(0, "animation", true);
    spineRomulus.state.setAnimation(0, "animation", true);
    spineBrutus.state.setAnimation(0, "animation", true);

    spineBrutus.state.timeScale = 0.3;
    spineRomulus.state.timeScale = 0.3;
    spineJoan.state.timeScale = 0.3;

    // canvas中的内容按zIndex排序
    brutus.stage.sortableChildren = true;

    if (window.innerWidth < 600) {
      const offsetHeight = (window.innerHeight - 600) / 2;
      spineBrutus.x = brutus.screen.width / 2; // 960
      spineBrutus.y = brutus.screen.height - offsetHeight;

      spineRomulus.x = brutus.screen.width / 2 - 150;
      spineRomulus.y = brutus.screen.height - offsetHeight;

      spineJoan.x = brutus.screen.width / 2 + 150;
      spineJoan.y = brutus.screen.height - offsetHeight;

      mobileShadow.x = 540;
      mobileShadow.y = 300;
      mobileShadow.width = 856;
      mobileShadow.height = 902;

      spineBrutus.scale.set(0.6);
      spineRomulus.scale.set(0.57);
      spineJoan.scale.set(0.57);

      brutus.stage.addChild(spineRomulus, spineJoan, spineBrutus);
    } else {
      if (window.innerWidth > 1600) {
        spineBrutus.scale.set(0.75);
        spineRomulus.scale.set(0.72);
        spineJoan.scale.set(0.72);
        spineBrutus.x = brutus.screen.width - 500;
        spineBrutus.y = brutus.screen.height + 350;

        spineRomulus.x = brutus.screen.width - 750;
        spineRomulus.y = brutus.screen.height + 300;

        spineJoan.x = brutus.screen.width - 300;
        spineJoan.y = brutus.screen.height + 320;
      } else {
        spineBrutus.scale.set(0.68);
        spineRomulus.scale.set(0.65);
        spineJoan.scale.set(0.65);

        spineBrutus.x = brutus.screen.width - 400;
        spineBrutus.y = brutus.screen.height + 350;

        spineRomulus.x = brutus.screen.width - 650;
        spineRomulus.y = brutus.screen.height + 300;

        spineJoan.x = brutus.screen.width - 200;
        spineJoan.y = brutus.screen.height + 320;
      }

      romulusShadow.x = canvasWidth / 10;
      romulusShadow.y = canvasHeight / 20;
      romulusShadow.width = (canvasWidth * 9) / 10;
      romulusShadow.height = canvasHeight;

      joanShadow.x = canvasWidth - 488;
      joanShadow.y = canvasHeight - 645;
      joanShadow.width = 488;
      joanShadow.height = 645;

      brutus.stage.addChild(
        romulusShadow,
        joanShadow,
        spineRomulus,
        spineJoan,
        spineBrutus
      );
    }
  })  

export default function CharacterSpine({ setHomeCanvasLoaded }) {
  const brutusRef = useRef();

  useEffect(() => {
    if (brutusRef.current) {
      brutusRef.current.appendChild(brutus.view);
      loadCanvas.onComplete.add(() => setHomeCanvasLoaded(true))
    }
  }, [setHomeCanvasLoaded]);


  return (
    <div
      ref={brutusRef}
      className={clsx("character-anime", isHD && "scale05")}
    />
  );
}
