import { jumpList } from "../const";

export default function AffixNav({changeBackground}) {


  return (
    <div className="affix-nav" style={changeBackground ? {backgroundColor: 'rgba(255, 255, 255, 0.16)'} : {}}>
      {
        jumpList.map(({ target, href, src, alt }) => (
          <a key={alt} target={target} href={href}>
            <img src={src} alt={alt} />
          </a>
      ))}
    </div>
  )
}