import star from "../images/star_select.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, EffectCoverflow, Navigation } from "swiper";

import RoleCard from "./RoleCard";
import { useState, useEffect } from "react";
import useIsMobile from "../utils/useIsMobile";

export default function SectionCarousel({ isActive }) {
  const isMobile = useIsMobile();
  const [activeCard, setActiveCard] = useState(0);

  const [swiper, setSwiper] = useState(null);

  const handleSlideChange = (param) => {
    setActiveCard(param.activeIndex);
  };

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(activeCard);
    }
  }, [activeCard]);

  return (
    <section className="normal-section play-section">
      <div className="play-cards">
        <h2 className="section-title">
          <img src={star} />
          Play-To-Earn
          <img src={star} />
        </h2>
        {isMobile ? (
          <>
            <div className="cards-container">
              <div className="column-flex">
                {Array.from({ length: 3 }).map((x, i) => (
                  <RoleCard key={i} isActive={isActive} index={i} />
                ))}
              </div>
              <div className="column-flex">
                {Array.from({ length: 4 }).map((x, i) => (
                  <RoleCard key={i + 3} isActive={isActive} index={i + 3} />
                ))}
              </div>
              <div className="column-flex">
                {Array.from({ length: 3 }).map((x, i) => (
                  <RoleCard key={i + 7} isActive={isActive} index={i + 7} />
                ))}
              </div>
            </div>
            <a
              href="https://app.tinyworlds.io/marketplace/list#list"
              target="_blank"
              rel="noreferrer"
              className="mobile-buy-button-link"
            >
              <button className="buy-button">BUY NOW</button>
            </a>
          </>
        ) : (
          <Swiper
            style={{
              transform: "scale(0.38)",
            }}
            className="play-swiper"
            effect="coverflow"
            slideToClickedSlide={true}
            loop={true} //循环
            loopedSlides={10}
            centeredSlides={true}
            onSwiper={(swiper) => {
              setSwiper(swiper);
            }}
            onSlideChange={handleSlideChange}
            modules={[Autoplay, EffectCoverflow]}
            speed={500}
            autoplay={{
              delay: 3000,
              disableOnInteraction: true,
              stopOnLastSlide: false,
              pauseOnMouseEnter: true,
            }}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 400,
              modifier: 1,
              slideShadows: false,
            }}
            breakpoints={{
              576: {
                slidesPerView: 2,
                spaceBetween: -430,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: -430,
              },
              1200: {
                slidesPerView: 4,
                spaceBetween: -250,
              },
              1400: {
                slidesPerView: 4,
                spaceBetween: -250,
              },
              1920: {
                slidesPerView: 4,
                spaceBetween: -300,
              },
            }}
            mousewheel={true}
            pagination={false}
          >
            {Array.from({ length: 10 }).map((x, i) => (
              <SwiperSlide key={i}>
                {({ isActive }) => (
                  <RoleCard
                    isActive={isActive}
                    index={i}
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </section>
  );
}
