import background from "../images/section-video/bg1.png";
import videoBg from "../images/link/B02.png";
import star from "../images/star_select.svg";
import poster from "../images/link/poster.png";

export default function SectionVideo({ isActive }) {
  return (
    <section className="video-section">
      <div className="introduction-content">
        <h2
          className="section-title"
        >
          <img src={star} />
          INTRODUCTION
          <img src={star} />
        </h2>

        <div
          className="video-container"
        >
          <img src={videoBg} />
          <video
            id="tiny-world-introduction"
            poster={poster}
            preload="metadata"
            playsInline
            controls="controls"
          >
            <source
              src="https://media.tinyworlds.io/tiny-world.mp4"
              type="video/mp4"
            />
            你的浏览器不支持Video标签
          </video>
          {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/uIfifqcxP8Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe> */}
        </div>
        <div
          className="description"
        >
          Tiny World is a blockchain game universe that combines NFT, DeFi and
          Gaming to create a truly stunning experience for users. Collect over
          100 Tiny Hero NFTs and start your epic journey. Battle your way to the
          top of the leaderboards or enter the dungeons to realize your
          play-to-earn experience. Stake your tokens and awesome NFTs for a
          different way to earn using the platform's DeFi products.
        </div>
      </div>
    </section>
  );
}
