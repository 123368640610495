import React, { useEffect, useMemo, useState } from "react";

import logoTop from "../images/logo.png";
import { headerList } from "../utils/constants";
import "./header.css";
import launch from "../images/link/button/launch-normal.png";

import star from "../images/star_select.svg";
import star_unselect from "../images/star_unselect.svg";

export default function Header({ active, slideTo, toFooter }) {
  const handleClick = (e) => {
    const activeNum = Number(e.target.dataset.active);
    slideTo(activeNum);
  };

  return (
    <header>
      <div
        className="header-logo"
        style={{
          transform: active > 0 && !toFooter ? undefined : "translateY(-30vh)",
        }}
      >
        <img src={logoTop} alt="" />
      </div>
      <nav className={`${!toFooter ? "show-nav" : "hidden-nav"}`}>
        <ul>
          {headerList.map(({ text, href }, index) => {
            return (
              <li
                key={text}
                className={active === index ? "active" : undefined}
              >
                {active === index ? (
                  <img src={star} alt="" />
                ) : (
                  <img src={star_unselect} alt="" />
                )}
                <span data-active={index} onClick={handleClick}>
                  {text}
                </span>
              </li>
            );
          })}
        </ul>
      </nav>

      <a
        href="https://app.tinyworlds.io"
        style={{
          transform: active > 0 && !toFooter ? undefined : "translateY(-30vh)",
        }}
      >
        <button className="header-launch-button">
          LAUNCH APP
        </button>
      </a>
    </header>
  );
}
