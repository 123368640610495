import castle_1 from "../images/link/roadmap/signpost-castle-1.png";
import castle_2 from "../images/link/roadmap/signpost-castle-2.png";
import castle_3 from "../images/link/roadmap/signpost-castle-3.png";
import castle_4 from "../images/link/roadmap/signpost-castle-4.png";
import castle_5 from "../images/link/roadmap/signpost-castle-5.png";
import castle_6 from "../images/link/roadmap/signpost-castle-6.png";
import castle_7 from "../images/link/roadmap/signpost-castle-7.png";
import castle_8 from "../images/link/roadmap/signpost-castle-8.png";

const touchStartAndEndPosition = {
  scrollTop: undefined,
  y: undefined,
  maxScrollTop: undefined,
};

export default function SectionRoadMap(props) {
  const { toNext, toPrev } = props;
  return (
    <div
      className="normal-section roadmap-section"
      onTouchStart={(e) => {
        // console.log("onTouchStart e", e);
        const target = e.currentTarget;
        touchStartAndEndPosition.maxScrollTop =
          target.scrollHeight - target.clientHeight;
        // console.log("scrollHeight", target.scrollHeight);
        // console.log("clientHeight", target.clientHeight);
        // console.log("scrollTop1", target.scrollTop);
        touchStartAndEndPosition.scrollTop = target.scrollTop;
        const y = e.changedTouches[0].clientY;
        // console.log("onTouchStart y1", y);
        // console.log("onTouchStart y2", e.changedTouches[0].pageY);
        touchStartAndEndPosition.y = y;
        // console.log("onTouchStart e.changedTouches[0]", e.changedTouches[0]);
      }}
      onTouchEnd={(e) => {
        const target = e.currentTarget;
        // console.log("scrollTop2", target.scrollTop);
        if (target.scrollTop === touchStartAndEndPosition.scrollTop) {
          // 判断手指滑动的方向
          const y = e.changedTouches[0].clientY;
          // console.log("onTouchEnd y1", y);
          // console.log("onTouchEnd y2", e.changedTouches[0].pageY);
          if (y < touchStartAndEndPosition.y - 10) {
            toNext();
          } else if (y > touchStartAndEndPosition.y + 10) {
            toPrev();
          }
        } else if (target.scrollTop < 0) {
          toPrev();
        } else if (
          touchStartAndEndPosition.maxScrollTop &&
          target.scrollTop > touchStartAndEndPosition.maxScrollTop
        ) {
          toNext();
        }
      }}
    >
      <div className="mobile-noswiping-content">
        <div className="mobile-signpost mobile-signpost-1">
          <div className="mobile-signpost-icon">
            <img src={castle_1} alt="" />
          </div>
          <div className="mobile-signpost-content">
            <div className="signpost-date">2022.Q1</div>
            <ul className="signpost-content-item">
              <li className="checked">Tiny Farm Launch</li>
              <li className="checked">Tiny Kingdom Launch</li>
              <li className="checked">Tiny DAO</li>
              <li className="checked">Add Liquidity on Dex</li>
              <li className="checked">Certik Audit</li>
            </ul>
          </div>
        </div>

        <div className="mobile-signpost mobile-signpost-2">
          <div className="mobile-signpost-icon">
            <img src={castle_2} alt="" />
          </div>
          <div className="mobile-signpost-content">
            <div className="signpost-date">2022.Q2</div>
            <ul className="signpost-content-item">
              <li className="checked">Referral System</li>
              <li className="checked">PancakeSwap Token Listing</li>
              <li className="checked">Binance Live Channel</li>
              <li className="checked">Listing on the TOP CEX</li>
              <li className="checked">Tiny Kingdom: Quest</li>
            </ul>
          </div>
        </div>

        <div className="mobile-signpost mobile-signpost-3">
          <div className="mobile-signpost-icon">
            <img src={castle_3} alt="" />
          </div>
          <div className="mobile-signpost-content">
            <div className="signpost-date">2022.Q3</div>
            <ul className="signpost-content-item">
              <li className="checked">
                Tiny Kingdom 2.0:
                <br />
                -BOSS Raid
                <br />
                -New Items
              </li>
              <li className="checked">New Tiny Heroes(UR)</li>
              <li className="checked">Tiny Lord Release</li>
              <li className="checked">Economic Model Update</li>
              <li className="checked">Game Mobile Version</li>
            </ul>
          </div>
        </div>

        <div className="mobile-signpost mobile-signpost-4">
          <div className="mobile-signpost-icon">
            <img src={castle_4} alt="" />
          </div>
          <div className="mobile-signpost-content">
            <div className="signpost-date">2022.Q4</div>
            <ul className="signpost-content-item">
              <li className="checked">New Style of NFT:SPIRIT</li>
              <li className="checked">Tiny Pet NFT</li>
              <li className="checked">Tiny Kingdom - Pet System</li>
              <li className="checked">DAO Governance</li>
            </ul>
          </div>
        </div>

        <div className="mobile-signpost mobile-signpost-5">
          <div className="mobile-signpost-icon">
            <img src={castle_5} alt="" />
          </div>
          <div className="mobile-signpost-content">
            <div className="signpost-date">2023.Q1</div>
            <ul className="signpost-content-item">
              <li className="checked">Tiny Kingdom - PvP Arena</li>
              <li className="checked">Tiny Lord 2.0</li>
              <li>Tiny Dungeons Land Publish</li>
            </ul>
          </div>
        </div>

        <div className="mobile-signpost mobile-signpost-6">
          <div className="mobile-signpost-icon">
            <img src={castle_6} alt="" />
          </div>
          <div className="mobile-signpost-content">
            <div className="signpost-date">2023.Q2</div>
            <ul className="signpost-content-item">
              <li className="checked">
                TinySwap - an NFT Exchange designed for games
              </li>
              <li>Tiny Dungeons Release</li>
              <li>Tiny Profile Picture</li>
            </ul>
          </div>
        </div>

        <div className="mobile-signpost mobile-signpost-7">
          <div className="mobile-signpost-icon">
            <img src={castle_7} alt="" />
          </div>
          <div className="mobile-signpost-content">
            <div className="signpost-date">2023.Q3</div>
            <ul className="signpost-content-item">
              <li>TinySwap open for 3rd-party projects</li>
              <li>Tiny Guild</li>
            </ul>
          </div>
        </div>

        <div className="mobile-signpost mobile-signpost-8">
          <div className="mobile-signpost-icon">
            <img src={castle_8} alt="" />
          </div>
          <div className="mobile-signpost-content">
            <div className="signpost-date">2023.Q4</div>
            <ul className="signpost-content-item">
              <li>Tiny Dungeons 2.0</li>
              <li>Tiny Gameverse Official Launch</li>
              <li>New Tiny Game Launch</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
