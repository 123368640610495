import twPng from "./images/bottom/icon_tw.png";
import tgPng from "./images/bottom/icon_tg.png";
import dcPng from "./images/bottom/icon_dc.png";
import gbPng from "./images/bottom/icon_gb.png";
import mdPng from "./images/bottom/icon_md.png";
import blivePng from "./images/bottom/icon_blive.png";
import ttPng from "./images/bottom/icon_tt.png";
import fbImg from"./images/bottom/fb.svg"
import insImg from"./images/bottom/ins.svg"

export const hrefJump = {
  PRODUCTS: [
    {
      alt: "Tiny Farm",
      href: "https://app.tinyworlds.io/tinyvault/nft-farming/list",
      target: "_blank",
    },
    {
      alt: "Tiny Kingdom",
      href: "https://app.tinyworlds.io/game/dashboard",
      target: "_blank",
    },
    {
      alt: "NFT Marketplace",
      href: "https://app.tinyworlds.io/marketplace/list#list",
      target: "_blank",
    },
  ],
  SERVICE: [
    {
      alt: "Buy TINC",
      href: "https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x05aD6E30A855BE07AfA57e08a4f30d00810a402e",
      target: "_blank",
    },
  ],
  SUPPORT: [
    {
      alt: "Docs",
      href: "https://docs.tinyworlds.io/",
      target: "_blank",
    },
    {
      alt: "Guide",
      href: "https://app.tinyworlds.io/guide?select=0",
      target: "_blank",
    },
    {
      alt: "Give us a feedback",
      href: "mailto:business@tinyworlds.io",
      target: "_blank",
    },
  ],
};

export const jumpList = [
  {
    src: twPng,
    alt: "Twitter",
    href: "https://twitter.com/tinyworldgamefi",
    target: "_blank",
  },
  {
    src: tgPng,
    alt: "Telegram",
    href: "https://t.me/tinyworld_en",
    target: "_blank",
  },
  {
    src: dcPng,
    alt: "Discord",
    href: "https://discord.gg/jXTRTz7B7N",
    target: "_blank",
  },
  {
    src: mdPng,
    alt: "Medium",
    href: "https://medium.com/@tinyworld.gamefi",
    target: "_blank",
  },
  {
    src: gbPng,
    alt: "Youtube",
    href: "https://www.youtube.com/channel/UCmm9JeIkqes3sS6NZ2YGA0w",
    target: "_blank",
  },
  {
    src: blivePng,
    alt: "Binance Live",
    href: "https://www.binance.com/en/live/u/25851821",
    target: "_blank",
  },
  /* {
    src: ttPng,
    alt: "tiktok",
    href: "https://www.tiktok.com/t/ZTR6YEUJE/",
    target: "_blank",
  }, */
  {
    src: fbImg,
    alt: "Facebook",
    href: "https://www.facebook.com/profile.php?id=100084851317037",
    target: "_blank",
  },
  {
    src: insImg,
    alt: "Instagram",
    href: "https://www.instagram.com/tinyworld.gamefi/",
    target: "_blank",
  }
];
