import star from "../images/star_select.svg";
import { useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";
import sexangleCenter1 from "../images/link/sexangle-center1.png";
import sexangleCenter2 from "../images/link/sexangle-center2.png";
import sexangleLeft from "../images/link/sexangle-left.png";
import sexangleRight from "../images/link/sexangle-right.png";
import tp from "../images/partners/tp.png";
import hero from "../images/link/hero.png";
import snz from "../images/partners/snz.png";
import bns from "../images/partners/bns.png";
import dr from "../images/partners/dr.png";
import sl from "../images/partners/sl.png";
import fv from "../images/partners/fv.png";
import wyng from "../images/partners/wyng.png";
import hashkey from "../images/partners/hashkey.png";
import evg from "../images/partners/evg.png";
import sky9 from "../images/partners/sky9.png";
import cipholio from "../images/partners/cipholio.png";
import tw from "../images/partners/tw.png";
import metamask from "../images/partners/mm.png";
import certik from "../images/partners/certik.png";
import bitkeep from "../images/partners/bk.png";
import bnbchain from "../images/partners/bc.png";
import pancakeSwap from "../images/partners/ps.png";
import binanceLive from "../images/partners/bl.png";
import cg from "../images/partners/cg.png";
import cmc from "../images/partners/cmc.png";
import wb from "../images/partners/wb.png";
import bn from "../images/partners/bn.png";
import zerox from "../images/partners/zerox.png";
import nodereal from "../images/partners/nodereal.png";

import "./SectionPartner.css";
import useIsMobile from "../utils/useIsMobile";

const INVESTORS = [tp, snz, bns, wyng, fv, hashkey, evg, sky9, cipholio]

const PARTNERS = [tw, sl, metamask, certik, bitkeep, bnbchain, tp, pancakeSwap, binanceLive, cg, cmc, dr]

export default function SectionPartner({ isActive }) {
  const isMobile = useIsMobile()

  const springObj = {
    x: 1000,
    config: {
      duration: isActive ? 350 : 50,
      durationImg: isActive ? 800 : 10,
    },
    num: [1, 2, 3, 4, 5],
    rotateY: 90,
    boxDuration: isActive ? 600 : 50,
    rotateYDelay: 60,
  };

  const stylesLeft1 = useSpring({
    delay:
      springObj.boxDuration +
      (springObj.config.duration - springObj.rotateYDelay) * springObj.num[0],
    rotateY: isActive ? 0 : springObj.rotateY,
    config: { duration: springObj.config.duration },
  });
  const stylesRight1 = useSpring({
    delay:
      springObj.boxDuration +
      (springObj.config.duration - springObj.rotateYDelay) * springObj.num[0],
    rotateY: isActive ? 0 : springObj.rotateY,
    config: { duration: springObj.config.duration },
  });
  const stylesImg1 = useSpring({
    opacity: isActive ? 1 : 0,
    // rotateY: isActive ? 0 : springObj.rotateY / 2,
    delay:
      springObj.boxDuration +
      springObj.config.duration * springObj.num[0] +
      springObj.config.duration / 2,
    config: {
      duration: springObj.config.duration,
    },
  });
  const stylesLeft2 = useSpring({
    delay:
      springObj.boxDuration +
      (springObj.config.duration - springObj.rotateYDelay) * springObj.num[1],
    rotateY: isActive ? 0 : springObj.rotateY,
    config: { duration: springObj.config.duration },
  });
  const stylesRight2 = useSpring({
    delay:
      springObj.boxDuration +
      (springObj.config.duration - springObj.rotateYDelay) * springObj.num[1],
    rotateY: isActive ? 0 : springObj.rotateY,
    config: { duration: springObj.config.duration },
  });
  const stylesImg2 = useSpring({
    opacity: isActive ? 1 : 0,
    // rotateY: isActive ? 0 : springObj.rotateY / 2,
    delay:
      springObj.boxDuration +
      springObj.config.duration * springObj.num[1] +
      springObj.config.duration / 2,
    config: {
      duration: springObj.config.duration,
    },
  });
  const stylesLeft3 = useSpring({
    delay:
      springObj.boxDuration +
      (springObj.config.duration - springObj.rotateYDelay) * springObj.num[2],
    rotateY: isActive ? 0 : springObj.rotateY,
    config: { duration: springObj.config.duration },
  });
  const stylesRight3 = useSpring({
    delay:
      springObj.boxDuration +
      (springObj.config.duration - springObj.rotateYDelay) * springObj.num[2],
    rotateY: isActive ? 0 : springObj.rotateY,
    config: { duration: springObj.config.duration },
  });
  const stylesImg3 = useSpring({
    opacity: isActive ? 1 : 0,
    // rotateY: isActive ? 0 : springObj.rotateY / 2,
    delay:
      springObj.boxDuration +
      springObj.config.duration * springObj.num[2] +
      springObj.config.duration / 2,
    config: {
      duration: springObj.config.duration,
    },
  });
  const stylesLeft4 = useSpring({
    delay:
      springObj.boxDuration +
      (springObj.config.duration - springObj.rotateYDelay) * springObj.num[3],
    rotateY: isActive ? 0 : springObj.rotateY,
    config: { duration: springObj.config.duration },
  });
  const stylesRight4 = useSpring({
    delay:
      springObj.boxDuration +
      (springObj.config.duration - springObj.rotateYDelay) * springObj.num[3],
    rotateY: isActive ? 0 : springObj.rotateY,
    config: { duration: springObj.config.duration },
  });
  const stylesImg4 = useSpring({
    opacity: isActive ? 1 : 0,
    delay:
      springObj.boxDuration +
      springObj.config.duration * springObj.num[3] +
      springObj.config.duration / 2,
    config: {
      duration: springObj.config.duration,
    },
  });
  const stylesLeft5 = useSpring({
    delay:
      springObj.boxDuration +
      (springObj.config.duration - springObj.rotateYDelay) * springObj.num[4],
    rotateY: isActive ? 0 : springObj.rotateY,
    config: { duration: springObj.config.duration },
  });
  const stylesRight5 = useSpring({
    delay:
      springObj.boxDuration +
      (springObj.config.duration - springObj.rotateYDelay) * springObj.num[4],
    rotateY: isActive ? 0 : springObj.rotateY,
    config: { duration: springObj.config.duration },
  });
  const stylesImg5 = useSpring({
    opacity: isActive ? 1 : 0,
    delay:
      springObj.boxDuration +
      springObj.config.duration * springObj.num[4] +
      springObj.config.duration / 2,
    config: {
      duration: springObj.config.duration,
    },
  });
  const sexangleLeftBox = useSpring({
    opacity: isActive ? 1 : 0,
    x: isActive ? 0 : -springObj.x,
    config: {
      duration: springObj.boxDuration - 100,
    },
  });
  const sexangleRightBox = useSpring({
    opacity: isActive ? 1 : 0,
    x: isActive ? 0 : springObj.x,
    config: {
      duration: springObj.boxDuration - 100,
    },
  });

  const binanceLogoStyle = {
    maxHeight: '11rem',
    maxWidth: '11rem'
  }

  if (isMobile) {
    return (
      <section className="normal-section partner-section">
        <div className="mobile-investors">
          <h2 className="section-title">
            <img src={star} />
              INVESTORS
            <img src={star} />
          </h2>

          <div className="mobile-investor-matrix">
            {
              INVESTORS.map((url, i) => (
                <div key={url} className="mobile-investor-item">
                  <img src={url} alt="" />
                </div>
              ))
            }
          </div>


        </div>

        <div className="mobile-partners">
          <h2 className="section-title">
            <img src={star} />
              PARTNERS
            <img src={star} />
          </h2>

          <div className="mobile-partner-matrix">
            {
              PARTNERS.map((url, i) => (
                <div key={url} className="mobile-partner-item">
                  <img src={url} alt="" />
                </div>
              ))
            }
          </div>

        </div>
      </section>
    )
  }

  return (
    <section className="normal-section partner-section">
      <div
        className="partner-content"
        style={{
          transform:`scale(${window.innerWidth < 1920 ? window.innerWidth / 1920 : (window.innerWidth / 1920) * 1})`
        }}
      >
        <div className="sexangle-box">
          <animated.div
            className="sexangle-box-animate "
            style={sexangleLeftBox}
          >
            <animated.div className="sexangle-box-center sexangle-box-left5">
              <animated.div className="sexangle" style={stylesLeft5}>
                <img src={sexangleLeft} alt="" />
                <a herf={"#"}>
                  <animated.img
                    style={{
                      ...stylesImg5,
                    }}
                    src={tw}
                    alt=""
                  />
                </a>
              </animated.div>
            </animated.div>
            <animated.div className="sexangle-box-center sexangle-box-left4">
              <animated.div className="sexangle" style={stylesLeft4}>
                <img src={sexangleLeft} alt="" />
                <a herf={"#"}>
                  <animated.img
                    style={{
                      ...stylesImg4,
                    }}
                    src={tp}
                    alt=""
                  />
                </a>
              </animated.div>
              <animated.div className="sexangle" style={stylesLeft4}>
                <img src={sexangleLeft} alt="" />
                <span>
                  <animated.img
                    style={{
                      ...stylesImg4,
                      ...{
                        marginRight: "0.8rem",
                      },
                    }}
                    src={wb}
                    alt=""
                  />
                </span>
              </animated.div>
              <animated.div className="sexangle" style={stylesLeft4}>
                <img src={sexangleLeft} alt="" />
                <a herf={"#"}>
                  <animated.img
                    style={{
                      ...stylesImg4,
                    }}
                    src={sl}
                    alt=""
                  />
                </a>
              </animated.div>
              <animated.div className="sexangle" style={stylesLeft4}>
                <img src={sexangleLeft} alt="" />
                <a herf={"#"}>
                  <animated.img
                    style={{
                      ...stylesImg4,
                    }}
                    src={certik}
                    alt=""
                  />
                </a>
              </animated.div>
            </animated.div>
            <animated.div className="sexangle-box-center sexangle-box-left3">
              <animated.div className="sexangle" style={stylesLeft3}>
                <img src={sexangleLeft} alt="" />
                <a herf={"#"}>
                  <animated.img
                    style={{
                      ...stylesImg3,
                      ...binanceLogoStyle,
                      width: '11rem'
                    }}
                    src={pancakeSwap}
                    alt=""
                  />
                </a>
              </animated.div>
              <animated.div className="sexangle" style={stylesLeft3}>
                <img src={sexangleLeft} alt="" />
                <a herf={"#"}>
                  <animated.img
                    style={{
                      ...stylesImg3,
                    }}
                    src={dr}
                    alt=""
                  />
                </a>
              </animated.div>
              <animated.div className="sexangle" style={stylesLeft3}>
                <img src={sexangleLeft} alt="" />
                <span>
                  <animated.img
                    style={{
                      ...stylesImg3,
                      maxWidth: '9rem'
                    }}
                    src={nodereal}
                    alt=""
                  />
                </span>
              </animated.div>
            </animated.div>
            <animated.div className="sexangle-box-center sexangle-box-left2">
              <animated.div className="sexangle" style={stylesLeft3}>
                <img src={sexangleLeft} alt="" />
                <span>
                  <animated.img
                    style={{
                      ...stylesImg3,
                      ...binanceLogoStyle,
                      width: '10rem'
                    }}
                    src={metamask}
                    alt=""
                  />
                </span>
              </animated.div>
              <animated.div className="sexangle" style={stylesLeft2}>
                <img src={sexangleLeft} alt="" />
                <a herf={"#"}>
                  <animated.img
                    style={{
                      ...stylesImg2,
                    }}
                    src={cmc}
                    alt=""
                  />
                </a>
              </animated.div>
              <animated.div className="sexangle" style={stylesLeft2}>
                <img src={sexangleLeft} alt="" />
                <a herf={"#"}>
                  <animated.img
                    style={{
                      ...stylesImg2,
                    }}
                    src={cg}
                    alt=""
                  />
                </a>
              </animated.div>
              <animated.div className="sexangle" style={stylesLeft2}>
                <img src={sexangleLeft} alt="" />
                <a herf={"#"}>
                  <animated.img
                    style={{
                      ...stylesImg2,
                    }}
                    src={bitkeep}
                    alt=""
                  />
                </a>
              </animated.div>
            </animated.div>
            <animated.div className="sexangle-box-center sexangle-box-left1">
              <animated.div className="sexangle" style={stylesLeft2}>
                <img src={sexangleLeft} alt="" />
                <a herf={"#"}>
                  <animated.img
                    style={{
                      ...stylesImg2,
                      ...binanceLogoStyle,
                      width: '10.5rem'
                    }}
                    src={bnbchain}
                    alt=""
                  />
                </a>
              </animated.div>
              <animated.div className="sexangle" style={stylesLeft1}>
                <img src={sexangleLeft} alt="" />
                <a herf={"#"}>
                  <animated.img
                    style={{
                      ...stylesImg1,
                      ...binanceLogoStyle,
                      width: '10.5rem'
                    }}
                    src={binanceLive}
                    alt=""
                  />
                </a>
              </animated.div>
              <animated.div className="sexangle" style={stylesLeft1}>
                <img src={sexangleLeft} alt="" />
                <a herf={"#"}>
                  <animated.img
                    style={{
                      ...stylesImg1,
                      ...binanceLogoStyle,
                      width: '11rem'
                    }}
                    src={bn}
                    alt=""
                  />
                </a>
              </animated.div>
            </animated.div>
          </animated.div>
          <animated.div className="sexangle-box-center">
            <animated.div className="sexangle" style={sexangleRightBox}>
              <img src={sexangleCenter1} alt="" />
              <span>INVESTORS</span>
            </animated.div>
            <animated.div className="sexangle" style={sexangleLeftBox}>
              <img src={sexangleCenter2} alt="" />
              <span>PARTNERS</span>
            </animated.div>
          </animated.div>
          <animated.div
            className="sexangle-box-animate "
            style={sexangleRightBox}
          >
            <animated.div className="sexangle-box-center sexangle-box-right1">
              <animated.div className="sexangle" style={stylesRight1}>
                <img src={sexangleRight} alt="" />
                <span>
                  <animated.img
                    style={{
                      ...stylesImg1,
                      ...{
                        marginRight: "0.8rem",
                      },
                    }}
                    src={hero}
                    alt=""
                  />
                </span>
              </animated.div>
              <animated.div className="sexangle" style={stylesRight1}>
                <img src={sexangleRight} alt="" />
                <a herf={"#"}>
                  <animated.img
                    style={{
                      ...stylesImg1,
                    }}
                    src={wyng}
                    alt=""
                  />
                </a>
              </animated.div>
              <animated.div className="sexangle" style={stylesRight2}>
                <img src={sexangleRight} alt="" />
                <a herf={"#"}>
                  <animated.img
                    style={{
                      ...stylesImg2,
                    }}
                    src={cipholio}
                    alt=""
                  />
                </a>
              </animated.div>
            </animated.div>
            <animated.div className="sexangle-box-center sexangle-box-right2">
              <animated.div className="sexangle" style={stylesRight2}>
                <img src={sexangleRight} alt="" />
                <a herf={"#"}>
                  <animated.img
                    style={{
                      ...stylesImg2,
                    }}
                    src={evg}
                    alt=""
                  />
                </a>
              </animated.div>
              <animated.div className="sexangle" style={stylesRight2}>
                <img src={sexangleRight} alt="" />
                <a herf={"#"}>
                  <animated.img
                    style={{
                      ...stylesImg2,
                    }}
                    src={sky9}
                    alt=""
                  />
                </a>
              </animated.div>
              <animated.div className="sexangle" style={stylesRight2}>
                <img src={sexangleRight} alt="" />
                <span>
                  <animated.img
                    style={{
                      ...stylesImg2,
                      ...{
                        marginRight: "0.8rem",
                      },
                    }}
                    src={hero}
                    alt=""
                  />
                </span>
              </animated.div>
              <animated.div className="sexangle" style={stylesRight3}>
                <img src={sexangleRight} alt="" />
                <span>
                  <animated.img
                    style={{
                      ...stylesImg3,
                    }}
                    src={zerox}
                    alt=""
                  />
                </span>
              </animated.div>
            </animated.div>
            <animated.div className="sexangle-box-center sexangle-box-right3">
              <animated.div className="sexangle" style={stylesRight3}>
                <img src={sexangleRight} alt="" />
                <span>
                  <animated.img
                    style={{
                      ...stylesImg3,
                      ...{
                        marginRight: "0.8rem",
                      },
                    }}
                    src={hero}
                    alt=""
                  />
                </span>
              </animated.div>
              <animated.div className="sexangle" style={stylesRight3}>
                <img src={sexangleRight} alt="" />
                <a herf={"#"}>
                  <animated.img
                    style={{
                      ...stylesImg3,
                    }}
                    src={tp}
                    alt=""
                  />
                </a>
              </animated.div>
              <animated.div className="sexangle" style={stylesRight3}>
                <img src={sexangleRight} alt="" />
                <a herf={"#"}>
                  <animated.img
                    style={{
                      ...stylesImg3,
                    }}
                    src={bns}
                    alt=""
                  />
                </a>
              </animated.div>
            </animated.div>
            <animated.div className="sexangle-box-center sexangle-box-right4">
              <animated.div className="sexangle" style={stylesRight4}>
                <img src={sexangleRight} alt="" />
                <span>
                  <animated.img
                    style={{
                      ...stylesImg4,
                      ...{
                        marginRight: "0.8rem",
                      },
                    }}
                    src={hero}
                    alt=""
                  />
                </span>
              </animated.div>
              <animated.div className="sexangle" style={stylesRight4}>
                <img src={sexangleRight} alt="" />
                <a herf={"#"}>
                  <animated.img
                    style={{
                      ...stylesImg4,
                    }}
                    src={snz}
                    alt=""
                  />
                </a>
              </animated.div>
              <animated.div className="sexangle" style={stylesRight4}>
                <img src={sexangleRight} alt="" />
                <span>
                  <animated.img
                    style={{
                      ...stylesImg4,
                      ...{
                        marginRight: "0.8rem",
                      },
                    }}
                    src={hero}
                    alt=""
                  />
                </span>
              </animated.div>
              <animated.div className="sexangle" style={stylesRight4}>
                <img src={sexangleRight} alt="" />
                <a herf={"#"}>
                  <animated.img
                    style={{
                      ...stylesImg4,
                    }}
                    src={fv}
                    alt=""
                  />
                </a>
              </animated.div>
            </animated.div>
            <animated.div className="sexangle-box-center sexangle-box-right5">
              <animated.div className="sexangle" style={stylesRight5}>
                <img src={sexangleRight} alt="" />
                <a herf={"#"}>
                  <animated.img
                    style={{
                      ...stylesImg5,
                    }}
                    src={hashkey}
                    alt=""
                  />
                </a>
              </animated.div>
            </animated.div>
          </animated.div>
        </div>
      </div>
    </section>
  );
}
