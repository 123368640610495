export const headerList = [
  {
    text: "Home",
  },
  {
    text: "Introduction",
  },
  {
    text: "Play-To-Earn",
  },
  {
    text: "Games",
  },
  {
    text: "TINC Token",
  },
  {
    text: "Roadmap",
  },
  {
    text: "Partners",
  },
];