import star from "../images/star_select.svg";
import tp from "../images/partners/tp.png";
import hero from "../images/link/hero.png";
import snz from "../images/partners/snz.png";
import bns from "../images/partners/bns.png";
import dr from "../images/partners/dr.png";
import sl from "../images/partners/sl.png";
import fv from "../images/partners/fv.png";
import wyng from "../images/partners/wyng.png";
import hashkey from "../images/partners/hashkey.png";
import evg from "../images/partners/evg.png";
import sky9 from "../images/partners/sky9.png";
import cipholio from "../images/partners/cipholio.png";
import tw from "../images/partners/tw.png";
import metamask from "../images/partners/mm.png";
import certik from "../images/partners/certik.png";
import bitkeep from "../images/partners/bk.png";
import bnbchain from "../images/partners/bc.png";
import pancakeSwap from "../images/partners/ps.png";
import binanceLive from "../images/partners/bl.png";
import zerox from "../images/partners/zerox.png";
import cg from "../images/partners/cg.png";
import cmc from "../images/partners/cmc.png";
import wb from "../images/partners/wb.png";
import bn from "../images/partners/bn.png";
import nodereal from "../images/partners/nodereal.png";

const INVESTORS = [wyng, cipholio, evg, sky9, tp, bns, snz, fv, hashkey, hero, zerox, hero];

const PARTNERS = [
  bnbchain,
  binanceLive,
  bn,
  metamask,
  cmc,
  cg,
  bitkeep,
  pancakeSwap,
  dr,
  tp,
  wb,
  sl,
  certik,
  nodereal,
  tw,
];

const touchStartAndEndPosition = {
  scrollTop: undefined,
  y: undefined,
  maxScrollTop: undefined,
};

export default function SectionPartnersMobile(props) {
  const { toNext, toPrev } = props;
  // const isPartnerEmpty = (x) => x === PARTNERS.length - 2
  const isInvestorEmpty = (x) => x === INVESTORS.length - 1 || x === INVESTORS.length - 3

  return (
    <div
      className="normal-section partner-section"
      onTouchStart={(e) => {
        const target = e.currentTarget;
        touchStartAndEndPosition.maxScrollTop =
          target.scrollHeight - target.clientHeight;

        touchStartAndEndPosition.scrollTop = target.scrollTop;
        const y = e.changedTouches[0].clientY;
        touchStartAndEndPosition.y = y;
      }}
      onTouchEnd={(e) => {
        const target = e.currentTarget;
        if (target.scrollTop === touchStartAndEndPosition.scrollTop) {
          // 判断手指滑动的方向
          const y = e.changedTouches[0].clientY;
          if (y < touchStartAndEndPosition.y - 10) {
            toNext();
          } else if (y > touchStartAndEndPosition.y + 10) {
            toPrev();
          }
        } else if (target.scrollTop < 0) {
          toPrev();
        } else if (
          touchStartAndEndPosition.maxScrollTop &&
          target.scrollTop > touchStartAndEndPosition.maxScrollTop
        ) {
          toNext();
        }
      }}
    >
      <div className="mobile-partners-content mobile-noswiping-content">
        <div className="mobile-investors">
          <h2 className="section-title">
            <img src={star} />
            INVESTORS
            <img src={star} />
          </h2>

          <div className="mobile-investor-matrix">
            {INVESTORS.map((url, i) => (
              <div key={"investor-" + i} className={`mobile-investor-item ${isInvestorEmpty(i) ? "flex-center" : ""}`}>
                <img style={isInvestorEmpty(i) ? {height: 50, marginRight: 10} : {}} src={url} alt="" />
              </div>
            ))}
          </div>
        </div>

        <div className="mobile-partners">
          <h2 className="section-title">
            <img src={star} />
            PARTNERS
            <img src={star} />
          </h2>

          <div className="mobile-partner-matrix">
            {PARTNERS.map((url, i) => (
              <div
                key={"partner-" + i}
                className={`mobile-partner-item`}
              >
                <img src={url} alt="" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
