import { useEffect } from "react";

import star from "../images/star_select.svg";
import base from "../images/link/signpost-base.png";
import castle_1 from "../images/link/roadmap/signpost-castle-1.png";
import castle_2 from "../images/link/roadmap/signpost-castle-2.png";
import castle_3 from "../images/link/roadmap/signpost-castle-3.png";
import castle_4 from "../images/link/roadmap/signpost-castle-4.png";
import castle_5 from "../images/link/roadmap/signpost-castle-5.png";
import castle_6 from "../images/link/roadmap/signpost-castle-6.png";
import castle_7 from "../images/link/roadmap/signpost-castle-7.png";
import castle_8 from "../images/link/roadmap/signpost-castle-8.png";
import roadmapBg from "../images/link/roadmap-bg.png";

export default function SectionRoadMap({ isActive }) {
  return (
    <section className="normal-section roadmap-section">
      <div
        className="roadmap-container"
        style={
          {
            // transform: `scale(${
            //   window.innerWidth < 1920
            //     ? (0.92 * window.innerWidth) / 1920
            //     : (window.innerWidth / 1920) * 0.92
            // })`,
          }
        }
      >
        <img className="roadmap-container-bg" src={roadmapBg} alt="" />
        <div className="roadmap-content">
          <div className="signpost signpost-1">
            <div className="signpost-picture">
              <img className="signpost-base" src={base} alt="" />
              <img
                className={`signpost-castle ${isActive ? "castle-drop" : ""}`}
                src={castle_8}
                alt=""
              />
            </div>

            <div
              className={`signpost-content ${isActive ? "fadeInDownward" : ""}`}
            >
              <div className="signpost-date">2023.Q4</div>
              <ul className="signpost-content-item">
                <li>Tiny Dungeons 2.0</li>
                <li>Tiny Gameverse Official Launch</li>
                <li>New Tiny Game Launch</li>
              </ul>
            </div>
          </div>

          <div className="signpost signpost-2">
            <div className="signpost-picture">
              <img className="signpost-base" src={base} alt="" />
              <img
                className={`signpost-castle ${isActive ? "castle-drop" : ""}`}
                src={castle_7}
                alt=""
              />
            </div>

            <div
              className={`signpost-content ${isActive ? "fadeInDownward" : ""}`}
            >
              <div className="signpost-date">2023.Q3</div>
              <ul className="signpost-content-item">
                <li>TinySwap open for 3rd-party projects</li>
                <li>Tiny Guild</li>
              </ul>
            </div>
          </div>

          <div className="signpost signpost-3">
            <div className="signpost-picture">
              <img className="signpost-base" src={base} alt="" />
              <img
                className={`signpost-castle ${isActive ? "castle-drop" : ""}`}
                src={castle_6}
                alt=""
              />
            </div>

            <div
              className={`signpost-content ${isActive ? "fadeInDownward" : ""}`}
            >
              <div className="signpost-date">2023.Q2</div>
              <ul className="signpost-content-item">
                <li className="checked">
                  TinySwap - an NFT Exchange designed for games
                </li>
                <li>Tiny Dungeons Release</li>
                <li>Tiny Profile Picture</li>
              </ul>
            </div>
          </div>

          <div className="signpost signpost-4">
            <div className="signpost-picture">
              <img className="signpost-base" src={base} alt="" />
              <img
                className={`signpost-castle ${isActive ? "castle-drop" : ""}`}
                src={castle_5}
                alt=""
              />
            </div>

            <div
              className={`signpost-content  signpost-item-box-1 ${
                isActive ? "fadeInRight" : ""
              }`}
            >
              <img className="signpost-item-box-img-1" src={star} alt="" />
              <img className="signpost-item-box-img-2" src={star} alt="" />
              <div className="signpost-date">2023.Q1</div>
              <ul className="signpost-content-item">
                <li className="checked">Tiny Kingdom - PvP Arena</li>
                <li className="checked">Tiny Lord 2.0</li>
                <li>Tiny Dungeons Land Publish</li>
              </ul>
            </div>
          </div>

          <div className="signpost signpost-5">
            <div className="signpost-picture">
              <img className="signpost-base" src={base} alt="" />
              <img
                className={`signpost-castle ${isActive ? "castle-drop" : ""}`}
                src={castle_4}
                alt=""
              />
            </div>

            <div
              className={`signpost-content signpost-item-box-2 ${
                isActive ? "fadeInLeft" : ""
              }`}
            >
              <img className="signpost-item-box-img-1" src={star} alt="" />
              <img className="signpost-item-box-img-2" src={star} alt="" />
              <div className="signpost-date">2022.Q4</div>
              <ul className="signpost-content-item">
                <li className="checked">New Style of NFT:SPIRIT</li>
                <li className="checked">Tiny Pet NFT</li>
                <li className="checked">Tiny Kingdom - Pet System</li>
                <li className="checked">DAO Governance</li>
              </ul>
            </div>
          </div>

          <div className="signpost signpost-6">
            <div className="signpost-picture">
              <img className="signpost-base" src={base} alt="" />
              <img
                className={`signpost-castle ${isActive ? "castle-drop" : ""}`}
                src={castle_3}
                alt=""
              />
            </div>

            <div
              className={`signpost-content ${isActive ? "fadeInDownward" : ""}`}
            >
              <div className="signpost-date">2022.Q3</div>
              <ul className="signpost-content-item">
                <li className="checked">
                  Tiny Kingdom 2.0:
                  <br />
                  -BOSS Raid
                  <br />
                  -New Items
                </li>
                <li className="checked">New Tiny Heroes(UR)</li>
                <li className="checked">Tiny Lord Release</li>
                <li className="checked">Economic Model Update</li>
                <li className="checked">Game Mobile Version</li>
              </ul>
            </div>
          </div>

          <div className="signpost signpost-7">
            <div className="signpost-picture">
              <img className="signpost-base" src={base} alt="" />
              <img
                className={`signpost-castle ${isActive ? "castle-drop" : ""}`}
                src={castle_2}
                alt=""
              />
            </div>

            <div
              className={`signpost-content ${isActive ? "fadeInDownward" : ""}`}
            >
              <div className="signpost-date">2022.Q2</div>
              <ul className="signpost-content-item">
                <li className="checked">Referral System</li>
                <li className="checked">PancakeSwap Token Listing</li>
                <li className="checked">Binance Live Channel</li>
                <li className="checked">Listing on the TOP CEX</li>
                <li className="checked">Tiny Kingdom: Quest</li>
              </ul>
            </div>
          </div>

          <div className="signpost signpost-8">
            <div className="signpost-picture">
              <img className="signpost-base" src={base} alt="" />
              <img
                className={`signpost-castle ${isActive ? "castle-drop" : ""}`}
                src={castle_1}
                alt=""
              />
            </div>

            <div
              className={`signpost-content ${isActive ? "fadeInDownward" : ""}`}
            >
              <div className="signpost-date">2022.Q1</div>
              <ul className="signpost-content-item">
                <li className="checked">Tiny Farm Launch</li>
                <li className="checked">Tiny Kingdom Launch</li>
                <li className="checked">Tiny DAO</li>
                <li className="checked">Add Liquidity on Dex</li>
                <li className="checked">Certik Audit</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
