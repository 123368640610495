import star from "../images/star_select.svg";
import token from "../images/section-token/token.png";
import crown from "../images/section-token/crown.png";
import box from "../images/section-token/box.png";
import useIsMobile from "../utils/useIsMobile";

export default function SectionToken({ isActive }) {
  const isMobile = useIsMobile();

  return (
    <section className="normal-section token-section">
      <div className="token-content">
        <h2
          className="section-title"
        >
          <img src={star} />
          TINC Token
          <img src={star} />
        </h2>

        <div id="tiny-world-universe" className="scale-stretched">
          <div
            id="galaxy"
            style={
              isMobile
                ? { transform: `scale(${(0.47 * window.innerWidth) / 375})` }
                : {
                    transform: `scale(${
                      !isMobile ? window.innerWidth / 1920 : 1
                    })`,
                  }
            }
          >
            <div id="tiny-system" className="earth">
              <div id="tiny-kingdom" className="orbit">
                <div className="pos kingdom-pos">
                  <div className="tiny-planet tiny-kingdom"></div>
                </div>

                <div className="pos dao-pos">
                  <div className="tiny-planet tiny-dao"></div>
                </div>

                <div className="pos farm-pos">
                  <div className="tiny-planet tiny-farm"></div>
                </div>

                <div className="pos rune-pos">
                  <div className="tiny-planet tiny-rune"></div>
                </div>
              </div>

              <div id="token"></div>
            </div>
          </div>
        </div>

        <div className="game-desc">
          TINY COIN(TINC) is a key component of the Tiny World's decentralized game economy. It incentivizes players who actively interact with our game and rewards developers who contribute to the development of the game.
          {/* <ul>
            <li>Currency of The Game Economy</li>
            <li>Reward for Playing and Staking</li>
            <li>Decentralized Governance</li>
          </ul> */}
          <div className="token-desc-items">
            <div className="token-desc-item">
              <img src={token} alt="" />
              <span>Currency of The<br/> Game Economy</span>
            </div>
            <div className="token-desc-item">
              <img src={box} alt="" />
              <span>Reward for Playing<br/> and Staking</span>
            </div>
            <div className="token-desc-item" style={isMobile ? {alignItems: 'end'} : {}}>
              <img src={crown} alt="" />
              <span>Decentralized<br/> Governance</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
