import "./roleCard.css";
import napoleonPic from "../images/rolecard/napoleon-card.png";
import boudicaPic from "../images/rolecard/boudica-card.png";
import galileoPic from "../images/rolecard/galileo-card.png";
import medeaPic from "../images/rolecard/medea-card.png";
import musashiPic from "../images/rolecard/musashi-card.png";
import hammurabiPic from "../images/rolecard/hammurabi-card.png";
import robinPic from "../images/rolecard/robin-card.png";
import ur1 from "../images/rolecard/UR1.png";
import ur2 from "../images/rolecard/UR2.png";
import ur3 from "../images/rolecard/UR3.png";

import napoleonName from "../images/rolecard/napoleon-name.png";
import boudicaName from "../images/rolecard/boudica-name.png";
import galileoName from "../images/rolecard/galileo-name.png";
import medeaName from "../images/rolecard/medea-name.png";
import musashiName from "../images/rolecard/musashi-name.png";
import hammurabiName from "../images/rolecard/hammurabi-name.png";
import robinName from "../images/rolecard/robin-name.png";
import ur1Name from "../images/rolecard/UR1-name.png";
import ur2Name from "../images/rolecard/UR2-name.png";
import ur3Name from "../images/rolecard/UR3-name.png";

import ur1Anime from "../images/rolecard/UR1-anime.png";
import ur2Anime from "../images/rolecard/UR2-anime.png";
import ur3Anime from "../images/rolecard/UR3-anime.png";

import ur1Persona from "../images/rolecard/UR1-persona.png";
import ur2Persona from "../images/rolecard/UR2-persona.png";
import ur3Persona from "../images/rolecard/UR3-persona.png";

import ur1Slogan from "../images/rolecard/UR1-slogan.png";
import ur2Slogan from "../images/rolecard/UR2-slogan.png";
import ur3Slogan from "../images/rolecard/UR3-slogan.png";

import ranger from "../images/rolecard/ranger.png";
import wizard from "../images/rolecard/wizard.png";
import warrior from "../images/rolecard/warrior.png";

import gradeUR from "../images/rolecard/UR.png";
import gradeSSSR from "../images/rolecard/SSSR.png";
import { useState, useMemo, useEffect, createRef } from "react";
import urBg from "../images/rolecard/UR-bg.png";

import PIXI from "../pixiSpine";
import { useSwiper } from "swiper/react";

const canvasWidth = 490;
const canvasHeight = 490;

const app = new PIXI.Application({
  width: canvasWidth,
  height: canvasHeight,
  transparent: true,
});

const cardSpines = [
  {
    name: "spinenapoleon",
    url: "/pixi-spine/617/HERO_617.json",
    animate: null,
  },
  {
    name: "spineboudica",
    url: "/pixi-spine/601/HERO_601.json",
    animate: null,
  },
  {
    name: "spinegalileo",
    url: "/pixi-spine/623/HERO_623.json",
    animate: null,
  },
  {
    name: "spinemedea",
    url: "/pixi-spine/622/HERO_622.json",
    animate: null,
  },
  {
    name: "spinemusashi",
    url: "/pixi-spine/614/HERO_614.json",
    animate: null,
  },
  {
    name: "spinehammurabi",
    url: "/pixi-spine/621/HERO_621.json",
    animate: null,
  },
  {
    name: "spinerobin",
    url: "/pixi-spine/607/HERO_607.json",
    animate: null,
  },
];

for (let index = 0; index < cardSpines.length; index++) {
  const ele = cardSpines[index];
  app.loader.add(`${ele.name}`, `${ele.url}`);
}

const roleInfos = [
  {
    characterImg: napoleonPic,
    role: ranger,
    persona: "Emperor of the French",
    name: napoleonName,
    shadowPos: { bottom: "8%", left: "14%" },
  },
  {
    characterImg: boudicaPic,
    role: warrior,
    persona: "British Warrior Queen",
    name: boudicaName,
    shadowPos: { bottom: "6.5%", left: "14%" },
  },
  {
    characterImg: galileoPic,
    role: wizard,
    persona: "Italian Astronomer",
    name: galileoName,
    shadowPos: { bottom: "8%", left: "14%" },
  },
  {
    characterImg: medeaPic,
    role: wizard,
    persona: "Greek Priestess",
    name: medeaName,
    shadowPos: { bottom: "8%", left: "15%" },
  },
  {
    characterImg: musashiPic,
    role: warrior,
    persona: "Japanese Swordsman",
    name: musashiName,
    shadowPos: { bottom: "8%", left: "14%" },
  },
  {
    characterImg: hammurabiPic,
    role: wizard,
    persona: "King of the Babylonian Dynasty",
    name: hammurabiName,
    shadowPos: { bottom: "7%", left: "14%" },
  },
  {
    characterImg: robinPic,
    role: ranger,
    persona: "Heroic Outlaw",
    name: robinName,
    shadowPos: { bottom: "8%", left: "14%" },
  },
  {
    characterImg: ur1,
    role: ranger,
    anime: ur1Anime,
    persona: ur1Persona,
    slogan: ur1Slogan,
    name: ur1Name,
    shadowPos: { bottom: "12%", left: "15%" },
  },
  {
    characterImg: ur2,
    role: warrior,
    anime: ur2Anime,
    persona: ur2Persona,
    slogan: ur2Slogan,
    name: ur2Name,
    shadowPos: { bottom: "12%", left: "15%" },
  },
  {
    characterImg: ur3,
    role: wizard,
    anime: ur3Anime,
    persona: ur3Persona,
    slogan: ur3Slogan,
    name: ur3Name,
    shadowPos: { bottom: "9%", left: "14%" },
  },
];

const slogans = [
  <div className="character-slogan">
    <span>"Victory belongs to </span>
    <span>the most persevering."</span>
  </div>,

  <div className="character-slogan">
    "It takes skill to win a battle, but brains to win a war."
  </div>,

  <div
    className="character-slogan"
    style={{ textAlign: "center", margin: "1.66vw 0" }}
  >
    "Truth never ends."
  </div>,

  <div className="character-slogan">
    <span>"Fate can't take </span>
    <span>away courage."</span>
  </div>,

  <div className="character-slogan">
    "The way of the warrior is resolute acceptance of death."
  </div>,

  <div className="character-slogan">
    <span>"An eye for an eye, </span>
    <span>a tooth for a tooth."</span>
  </div>,

  <div className="character-slogan">
    <span>"Steal from the rich </span>
    <span>and give to the poor."</span>
  </div>,
];

let addedIndex = undefined;

export default function RoleCard({ isActive, index}) {
  const [isClicked, setIsClick] = useState(false);
  const playSwiper = useSwiper();

  useEffect(() => {
    if (!isActive) {
      setIsClick(false);
    }
  }, [isActive]);

  const active = useMemo(() => {
    if (window.innerWidth < 600) {
      return false;
    }

    return isClicked && isActive;
  }, [isClicked, isActive]);

  const characterClick = (e) => {
    e.stopPropagation();
    if (isClicked) {
      playSwiper.autoplay.start()
    } else {
      playSwiper.autoplay.stop()
    }

    setIsClick(!isClicked);
  };

  const dragonboneRef = createRef();

  useEffect(() => {
    // console.log('index, active', index, active);
    const element = dragonboneRef.current;
    if (element && index <= 6 && active) {
      app.loader.load((loader, resources) => {
        const ele = cardSpines[index];
        const spineAnime = new PIXI.spine.Spine(resources[ele.name].spineData);

        spineAnime.x = 500 / 2 - 10;
        spineAnime.y = 500 / 2;

        spineAnime.state.setAnimation(0, "idle", true);

        spineAnime.state.timeScale = 0.3;

        ele.animate = spineAnime;

        app.stage.addChild(cardSpines[index].animate);

        addedIndex = index;
      });
      element.appendChild(app.view);
    }

    return () => {
      if (index === addedIndex && app.view.parentElement) {
        app.stage.removeChild(cardSpines[index].animate);
        app.view.parentElement.removeChild(app.view);
        addedIndex = undefined;
      }
    };
  }, [active, index, dragonboneRef]);

  return (
    <div
      className={`${active ? "selected-card" : ""} character-card`}
      onClick={characterClick}
    >
      {index > 6 && <img className="img-UR-bg" src={urBg} alt="" />}
      <img
        style={active ? { display: "none" } : {}}
        src={roleInfos[index].characterImg}
      />
      <div
        className={`${
          active
            ? index > 6
              ? "selected-UR-card-bg"
              : "selected-SSSR-card-bg"
            : ""
        } ${index > 6 ? "character-card-UR-bg" : "character-card-SSSR-bg"}`}
      />
      <div className={`${active ? "card-mask" : ""}`} />
      <div className={`${active ? "character-card-content" : "hidden"}`}>
        <div className="character-animation">
          {index > 6 ? (
            <img
              className="role-character"
              style={{ marginTop: "2.5%" }}
              src={roleInfos[index].anime}
              alt=""
            />
          ) : (
            <div
              ref={dragonboneRef}
              style={{
                transform: `scale(${window.innerWidth / 1920})`,
              }}
              className="role-character-dragonbone"
            />
          )}
          {Array.from({ length: 70 }).map((x, i) => (
            <div className="star" key={i} />
          ))}
        </div>

        <div
          className="character-shadow"
          style={{
            bottom: roleInfos[index].shadowPos.bottom,
            left: roleInfos[index].shadowPos.left,
          }}
        />
        <div className="character-card-content-detail">
          <img
            className="character-role-img"
            src={roleInfos[index].role}
            alt=""
          />
          <img
            className="character-name"
            src={roleInfos[index].name}
            width={294}
            height={61}
            alt=""
          />
          <img
            className="character-grade"
            width={83}
            height={37}
            src={index > 6 ? gradeUR : gradeSSSR}
            alt=""
          />
          <div className="character-setting">
            {index > 6 ? (
              <img
                className="UR-setting"
                src={roleInfos[index].persona}
                alt=""
              />
            ) : (
              roleInfos[index].persona
            )}
          </div>
          {index > 6 ? (
            <img className="UR-slogan" src={roleInfos[index].slogan} alt="" />
          ) : (
            slogans[index]
          )}

          <a
            className="display-block"
            href="https://app.tinyworlds.io/marketplace/list#list"
            target="_blank"
            rel="noreferrer"
            onClick={e => e.stopPropagation()}
          >
            <button className="buy-button">BUY NOW</button>
          </a>
        </div>
      </div>
    </div>
  );
}
