import React from "react";
import "./footer.css";
import logoTop from "../images/logo.png";
import useIsMobile from "../utils/useIsMobile";
import NavFooter from "./NavFooter";
import { jumpList, hrefJump } from "../const";
import iconEmail from "../images/bottom/icon_email.png";

export default function Footer({ activeIndex, slideTo }) {
  const isMobile = useIsMobile();

  return (
    <footer>
      {!isMobile ? (
        <>
          <div className="footer-left">
            <img src={logoTop} alt="" />
            <div className="footer-jump-box">
              {Object.keys(hrefJump).map((str) => {
                return (
                  <div key={str} className="footer-jump-item">
                    <label>{str}</label>
                    {hrefJump[String(str)].map(
                      ({ href, alt, target }, index) => {
                        return (
                          <a
                            key={`${str}-${index}`}
                            target={target}
                            alt={alt}
                            href={href}
                          >
                            {alt}
                          </a>
                        );
                      }
                    )}
                  </div>
                );
              })}
            </div>
            {/* <ul>
              {nav.map((item) => (
                <li>
                  <span onClick={() => toItem(item.index)}>{item.value}</span>
                </li>
              ))}
            </ul> */}
          </div>
          <div className="footer-right">
            <span className="copyright">
              Copyright © LiquidPlay Ltd.
            </span>
            {/* <div className="footer-right-top-text">Join our community</div>
            <div className="footer-right-top">
              {jumpList.map(({ target, href, src, alt }, index) => (
                <a key={index} target={target} href={href}>
                  <img src={src} alt={alt} />
                </a>
              ))}
            </div>
            <div className="footer-right-email">
              <img src={iconEmail} alt="" />
              <a target="_blank" href="mailto:business@tinyworlds.io">business@tinyworlds.io</a>
            </div> */}
          </div>
        </>
      ) : (
        <div className="mobile-footer">
          <NavFooter></NavFooter>
        </div>
      )}
    </footer>
  );
}
