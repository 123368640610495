import star from "../images/star_select.svg";
import kingdom1 from "../images/section-games/kingdom-card-1.png"
import kingdom2 from "../images/section-games/kingdom-card-2.png"
import kingdom3 from "../images/section-games/kingdom-card-3.png"

import kingdomBg2 from "../images/link/kingdom-bg2.png";
import kingdomBg2_1 from "../images/link/kingdom-bg2-1.png";
import { useEffect, useMemo, useState } from "react";
import kingdomBg2_2 from "../images/link/kingdom-bg2-2.png";
import kingdomLogo from "../images/link/kingdom-logo.png";
import tinyfarmLogo from "../images/link/tinyfarm-logo.png";
import tinydungeonsLogo from "../images/link/tinydungeons-logo.png";
import dungeonPic1 from "../images/section-games/dungeons-card-1.png";
import dungeonPic2 from "../images/section-games/dungeons-card-2.png";
import farm1 from "../images/section-games/farm-1.png";
import farm2 from "../images/section-games/farm-2.png";

import naviArrow from "../images/link/arrow.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "./SectionGames.css";
import useIsMobile from "../utils/useIsMobile";
import clsx from "clsx";

export default function SectionGames({ isActive }) {
  const isMobile = useIsMobile();
  const [swiper, setSwiper] = useState(null);

  const [isDungeonsActive, setIsDungeonsActive] = useState(false)

  useEffect(() => {
    if (!isActive) {
      setIsDungeonsActive(false)
    }
  }, [isActive])

  const CarouselPokerImg1 = useMemo(
    () => (
      <div className="kingdom-carousel-poker one">
        <img className="kingdom-carousel-poker-card one" src={farm1} />
      </div>
    ),
    []
  );
  const CarouselPokerImg2 = useMemo(
    () => (
      <div className="kingdom-carousel-poker two">
        <img className="kingdom-carousel-poker-card two" src={farm2} />
      </div>
    ),
    []
  );

  return (
    <section className="normal-section game-section">
      <div className="game-content">
        <h2
          className="section-title"
        >
          <img src={star} />
          Games
          <img src={star} />
        </h2>

        <Swiper
          className={clsx("game-swiper", isActive ? "active-game-swiper" : "")}
          loop={true}
          autoplay={{
            delay: 1000 * 15,
          }}
          modules={[Pagination, Autoplay]}
          pagination={{
            enabled: true,
            clickable: true,
          }}
          onSwiper={(swiper) => {
            setSwiper(swiper);
          }}
          onTransitionEnd={(swiper) => {
            if (swiper.activeIndex === 3 || swiper.activeIndex === 0) {
              setIsDungeonsActive(true)
            }
          }}
        >
          <SwiperSlide>
            <div className="game-content-section game-content-kingdom">
              <div className="game-carousel kingdom-carousel">
                  <img className="" src={kingdom1} />
                  <img className="" src={kingdom2} />
                  <img className="" src={kingdom3} />
              </div>
              <div className="kingdom-content">
                <div className="kingdom-content-right-section">
                  {isMobile ? (
                    <div className="mobile-navigation">
                      <img
                        className="navigation-arrow-left"
                        src={naviArrow}
                        alt=""
                        onClick={() => swiper.slidePrev()}
                      />
                      <img
                        className="game-logo"
                        src={kingdomLogo}
                        alt="tiny kingdom"
                      />
                      <img
                        className="navigation-arrow-right"
                        src={naviArrow}
                        alt=""
                        onClick={() => swiper.slideNext()}
                      />
                    </div>
                  ) : (
                    <img
                      className="game-logo"
                      src={kingdomLogo}
                      alt="tiny kingdom"
                    />
                  )}
                  
                  <ul className="kingdom-content-desc">
                    <li>
                      Idle Game - enjoy great gaming experience with your spare
                      time.
                    </li>
                    <li>
                      More than 80 materials and 200 weapons - all game
                      properties are tradable.
                    </li>
                    <li>
                      Equipment recycling - another Play to Earn experience.
                    </li>
                    <li>
                      Arm your heroes with stronger weapons and make it to the top of the leaderboard for amazing rewards.
                    </li>
                  </ul>
                  <a
                    href="https://app.tinyworlds.io"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="launch-button mobile-launch-button">
                      LAUNCH APP
                    </button>
                  </a>
                </div>
              </div>
              <div>
                <img className="kingdom-bg-role" src={kingdomBg2} alt="" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="game-content-section game-content-farm">
              <div className="game-carousel farm-carousel">
                {CarouselPokerImg1}
                {CarouselPokerImg2}
              </div>
              <div className="kingdom-content">
                <div className="kingdom-content-right-section">
                  {isMobile ? (
                    <div className="mobile-navigation">
                      <img
                        className="navigation-arrow-left"
                        src={naviArrow}
                        alt=""
                        onClick={() => swiper.slidePrev()}
                      />
                      <img
                        className="game-logo"
                        src={tinyfarmLogo}
                        alt="tiny Farm"
                      />
                      <img
                        className="navigation-arrow-right"
                        src={naviArrow}
                        alt=""
                        onClick={() => swiper.slideNext()}
                      />
                    </div>
                  ) : (
                    <img
                      className="game-logo"
                      src={tinyfarmLogo}
                      alt="tiny Farm"
                    />
                  )}
                  <ul className="kingdom-content-desc">
                    <li>
                      Enjoy opening mystery runes under a transparent and fair mechanism.
                    </li>
                    <li>
                      Yield aggregator makes GameFi more profitable.
                    </li>
                    <li>
                      NFT yield farming enables frictionless flow of NFT in the DeFi world.
                    </li>
                    <li>
                      Liquidity mining accelerator with your NFTs.
                    </li>
                    <li>
                      NFT marketplace enables free trade of NFT assets.
                    </li>
                  </ul>
                  <a
                    href="https://app.tinyworlds.io"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="launch-button mobile-launch-button">
                      LAUNCH APP
                    </button>
                  </a>
                </div>
              </div>
              <div>
                <img
                  className="kingdom-bg-role farm-bg-role"
                  src={kingdomBg2_1}
                  alt=""
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="game-content-section game-content-dungeons">
              <div className="game-carousel dungeons-carousel">
                <img
                  loading="lazy"
                  className={isDungeonsActive ? "game-carousel-dungeon-pic1" : ""}
                  src={dungeonPic2}
                  width={522}
                  height={864}
                  alt=""
                />
                <img
                  loading="lazy"
                  className={isDungeonsActive ? "game-carousel-dungeon-pic2" : ""}
                  src={dungeonPic1}
                  width={521}
                  height={864}
                  alt=""
                />
              </div>
              <div className="kingdom-content">
                <div className="kingdom-content-right-section">
                  {isMobile ? (
                    <div className="mobile-navigation">
                      <img
                        className="navigation-arrow-left"
                        src={naviArrow}
                        alt=""
                        onClick={() => swiper.slidePrev()}
                      />
                      <img
                        className="game-logo"
                        src={tinydungeonsLogo}
                        alt="tiny Dungeons"
                      />
                      <img
                        className="navigation-arrow-right"
                        src={naviArrow}
                        alt=""
                        onClick={() => swiper.slideNext()}
                      />
                    </div>
                  ) : (
                    <img
                      className="game-logo"
                      src={tinydungeonsLogo}
                      alt="tiny Dungeons"
                    />
                  )}
                  <ul className="kingdom-content-desc dungeons-content-desc">
                    <li>
                      Simulation Game: own the land of your own Dungeon and become the ruler.
                    </li>
                    <li>
                      Build your own Dungeon and earn abundant rewards.
                    </li>
                    <li>
                      Assemble heroes and team up for an adventure to others' Dungeons and loot their rewards.
                    </li>
                  </ul>
                  <a
                    href="https://app.tinyworlds.io"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="launch-button mobile-launch-button">
                      LAUNCH APP
                    </button>
                  </a>
                </div>
              </div>
              <div>
                <img
                  className="kingdom-bg-role dungeons-bg-role"
                  src={kingdomBg2_2}
                  alt=""
                />
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
}
